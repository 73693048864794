<template>
    <div>
        <Suspense>
            <NuxtPage />
        </Suspense>
    </div>
</template>

<script setup lang="ts">
import { useCountry } from '~/stores/country';
import getContentInfo from '~/api/contentInfo/getContentInfo';
import { useLayout } from '~/stores/layoutStore';
import { useTranslateLayOut } from '~/stores/translateStore';
import { useLang } from '~/stores/langStore';

const url = useRequestURL();
const { locale, setLocaleCookie } = useI18n();
const countreyStore = useCountry();
const translateStore = useTranslateLayOut();
const layoutPageStore = useLayout();
const langStore = useLang();
const countryDomain = url.host.split('.')[0];
const currentCountry = countryDomain.length > 3 ? countreyStore.currentCountryCode : countryDomain;
countreyStore.setCountryCode(currentCountry);
await useAsyncData(
    `contentinfo:${locale.value}`,
    async () => {
        const res = await getContentInfo(locale.value);
        translateStore.setTranslate(res.langHelpers);
        layoutPageStore.setLayoutData(res);
        countreyStore.updateCountryList(res.cities);
        langStore.setLanguages(res.header.languages);
        langStore.setLangCode(locale.value);
        setLocaleCookie(locale.value); // set locale if open link with another locale
        if (countreyStore.currentCity.id === undefined) {
            countreyStore.changeCity(res.cities.at(0)?.territoryList.at(0)); // set first city to select  if  have not presets storage;
        }
        return res;
    },
    {
        watch: [locale],
    }
);
</script>
