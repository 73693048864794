import httpCommon from '@/api/httpCommon/httpCommon';

/**
 * Asynchronously fetches country and city information based on the specified language.
 * Sends a GET request using the configured Axios instance from httpCommon.
 * @param {string} lang - The language code to specify the language for the request.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object containing country and city information.
 */
export const getCountry = async lang => {
    return await httpCommon().get('countries/cities', {
        headers: {
            locale: lang,
        },
    });
};

/**
 * Asynchronously performs a search for country information based on a search string and language.
 * Sends a GET request using the configured Axios instance from httpCommon.
 * @param {string} string - The search string used to query country information.
 * @param {string} lang - The language code to specify the language for the search results.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object containing the search results.
 */
export const getCountrySearch = async (string, lang) => {
    return await httpCommon().get('search', {
        params: {
            name: string,
        },
        headers: {
            locale: lang,
        },
    });
};
