import httpCommon from '@/api/httpCommon/httpCommon';
import type { responseLayout } from '~/@types/Respone';
import type { AxiosResponse } from 'axios';

/**
 * Asynchronously fetches content information based on language, contact block, and cities.
 * Sends a GET request using the configured Axios instance from httpCommon.
 * @param {string} lang - The language code to specify the language of the content.
 * @param {number} [contactBlock] - Optional parameter to specify the contact block. Defaults to 1.
 * @param {number} [cities] - Optional parameter to specify the cities. Defaults to 1.
 * @returns {Promise<responseLayout>} A promise that resolves to the content information layout.
 */
const getContentInfo = async (lang: string, contactBlock = 1, cities = 1): Promise<responseLayout> => {
    return await httpCommon()
        .get<AxiosResponse<responseLayout>>('page-data', {
            params: {
                contactBlock: contactBlock,
                cities: cities,
            },
            headers: {
                locale: lang,
            },
        })
        .then((res: AxiosResponse<responseLayout>) => res.data);
};

export default getContentInfo;
