import type { RootObjectLayout } from '~/@types/Layout';
import type { responseLayout } from '~/@types/Respone';
import { defineStore } from 'pinia';

export const useLayout = defineStore(
    'layoutStore',
    () => {
        let dataPageLayout = ref<RootObjectLayout>({} as RootObjectLayout);
        const setLayoutData = (data: responseLayout) => (dataPageLayout.value = { ...data });
        const pages = computed(() => dataPageLayout.value?.menu || []);
        const getCommunicationPhone = () => dataPageLayout.value.contactBlock?.tel_communication.content;
        return { dataPageLayout, pages, setLayoutData, getCommunicationPhone };
    },
    {
        persist: true,
    }
);
