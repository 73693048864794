import { defineStore } from 'pinia';
import { getCountry } from '~~/api/getCountry/getCountry';

export const useCountry = defineStore({
    id: 'country',
    state: () => ({
        countryList: {},
        currentCity: {},
        currentCountry: {},
        currentCountryCode: 'kz',
    }),
    getters: {
        getDefaultCountry: state => {
            return state.countryList.find(item => item.id === 1);
        },
        getCurrentSite: state => {
            return state.getDefaultCountry.territoryList?.find(item => item.id === 2);
        },
        getSelectedCity: state => {
            return state?.currentCity ?? false;
        },
        getSelectedCountry: state => {
            return Object.keys(state?.currentCountry)?.length ? state.currentCountry : state?.getDefaultCountry;
        },
    },

    actions: {
        async getCountryList(lang) {
            const response = await getCountry(lang);
            this.setCities(response.data);
        },
        setCities(data) {
            const city =
                data[0]?.territoryList?.find(item => {
                    return item.id === this.currentCity?.id;
                }) ?? data[0]?.territoryList?.find(item => item.id === 2);
            this.changeCity(city);
            this.updateCountryList(data);
        },
        updateCountryList(list) {
            this.countryList = list;
        },
        changeCity(city) {
            this.currentCity = city;
        },
        changeCountry(country) {
            this.currentCountry = country;
        },
        setCountryCode(code) {
            this.currentCountryCode = code;
        },
    },
    persist: true,
});
