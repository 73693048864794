import { useRuntimeConfig } from '#app';
import axios from 'axios';
/**
 * Creates and configures an Axios instance for HTTP requests.
 * Utilizes runtime configuration to set the API base URL.
 * Includes a global response interceptor for error logging.
 * @returns {AxiosInstance} A configured Axios instance.
 */
const httpCommon = () => {
    const runtimeConfig = useRuntimeConfig();

    const api = axios.create({
        baseURL: `${runtimeConfig.API_SERVER_HOST ?? ''}${runtimeConfig.public.API_BASE_URL}`,
    });

    // axios global error handler
    api.interceptors.response.use(
        response => response,
        error => {
            console.error(error);
            return error;
        }
    );

    return api;
};

export default httpCommon;
