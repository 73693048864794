import httpCommon from '~/api/httpCommon/httpCommon';

export default {
    async getPagesApp(locale: string) {
        const data = await httpCommon();
        return data.get('pages', { params: { locale } }).then(res => res.data);
    },
    async getPagesData(locale: string, id: number | string) {
        const data = await httpCommon();
        return data.get(`pages/${id}`, { params: { locale } }).then(res => res.data);
    },
};
