import { default as index2crTMSGZqaMeta } from "/var/www/pecom.kz/www/frontend/pages/[page]/index.vue?macro=true";
import { default as aboutpZXAu3whOoMeta } from "/var/www/pecom.kz/www/frontend/pages/about.vue?macro=true";
import { default as hidden_index2Yz42VqRHzMeta } from "/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue?macro=true";
import { default as errorU7GULSpUfQMeta } from "/var/www/pecom.kz/www/frontend/pages/error.vue?macro=true";
import { default as BlockFaqZgEaGvxaw2Meta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue?macro=true";
import { default as ConsultationBlockAhOpOwUD3sMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue?macro=true";
import { default as ConsultationForminrlSIbhPvMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue?macro=true";
import { default as FilialOpenJbthkKJtXmMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue?macro=true";
import { default as FranchiseBlockchtQvB5k0VMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue?macro=true";
import { default as MapBlockpnBcktLilaMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue?macro=true";
import { default as PalletBlockJunpPFnHmfMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue?macro=true";
import { default as ModalErrord9IXNrJH1UMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue?macro=true";
import { default as ModalSuccesstfGvWHrwRQMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue?macro=true";
import { default as PalletBlockvDXuRvi2imMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue?macro=true";
import { default as PalletOnevuIHzQVgbXMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue?macro=true";
import { default as BlockDescription4O6gG5gFJBMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue?macro=true";
import { default as PartnersBlockgXcoBlBw2cMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue?macro=true";
import { default as PalletBlockpO69Y8tTgdMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue?macro=true";
import { default as PekPlusBlockq2kQB8xQeYMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue?macro=true";
import { default as indexT24sy03xQVMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/index.vue?macro=true";
import { default as AdvantageBlock5Q4D1KpyuIMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue?macro=true";
import { default as BannerSliderkHYyWpyyp5Meta } from "/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue?macro=true";
import { default as BlockTitleohuhgCmNKEMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue?macro=true";
import { default as ConsultationBlockxPM3ocg98tMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue?macro=true";
import { default as ConsultationFormAOT2AYWlx6Meta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue?macro=true";
import { default as ModalError3i6wRkA14VMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue?macro=true";
import { default as ModalSuccessHAGqFK2hJrMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue?macro=true";
import { default as indexyGq6samUNJMeta } from "/var/www/pecom.kz/www/frontend/pages/index/index.vue?macro=true";
import { default as DefaultAdvantagesSectionWVVd8qK39hMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue?macro=true";
import { default as DefaultHeaderSectionFfUCgkTtKhMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue?macro=true";
import { default as DescriptionSectioneaMEggcSbiMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue?macro=true";
import { default as FaqSectionpEgJPu2vjGMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue?macro=true";
import { default as InfoSectiona0ArwLLEC0Meta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue?macro=true";
import { default as MapSection16dHNm4yQMMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue?macro=true";
import { default as getAddressInfoppFppoVf0LMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js?macro=true";
import { default as getDirectionParamsFromAddressInfopFwuSUxoGWMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js?macro=true";
import { default as getNeedCalculate15CVm2FJ00Meta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js?macro=true";
import { default as getSugggestgRMCr5QOUBMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js?macro=true";
import { default as getTransitCostBlockMessages68JuE3yKeMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js?macro=true";
import { default as isProductCode7CnUWfc1DKMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js?macro=true";
import { default as _91id_934b2N7PaGJZMeta } from "/var/www/pecom.kz/www/frontend/pages/news/[id].vue?macro=true";
import { default as indexYe5dKZsJo1Meta } from "/var/www/pecom.kz/www/frontend/pages/news/index.vue?macro=true";
import { default as CategoryD1AZXK8aTtMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue?macro=true";
import { default as CategoryPalletc3xu70dcmGMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue?macro=true";
import { default as PalletBlockGWpUqlpKfpMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue?macro=true";
import { default as HeadWithSearch7Oy0JowK22Meta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue?macro=true";
import { default as TableBlockL0Zy8p1xryMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue?macro=true";
import { default as TableRow6hMD4YcPkeMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue?macro=true";
import { default as TableSearchcsdeuJ5GaEMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue?macro=true";
import { default as index5dwxxXdK4IMeta } from "/var/www/pecom.kz/www/frontend/pages/package/index.vue?macro=true";
export default [
  {
    name: "page___kk",
    path: "/:page()",
    meta: index2crTMSGZqaMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue")
  },
  {
    name: "page___en",
    path: "/en/:page()",
    meta: index2crTMSGZqaMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue")
  },
  {
    name: "page___ru",
    path: "/ru/:page()",
    meta: index2crTMSGZqaMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue")
  },
  {
    name: "about___kk",
    path: "/about",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue")
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue")
  },
  {
    name: "documents-hidden_index___kk",
    path: "/documents/hidden_index",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue")
  },
  {
    name: "documents-hidden_index___en",
    path: "/en/documents/hidden_index",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue")
  },
  {
    name: "documents-hidden_index___ru",
    path: "/ru/documents/hidden_index",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue")
  },
  {
    name: "error___kk",
    path: "/error",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue")
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue")
  },
  {
    name: "error___ru",
    path: "/ru/error",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue")
  },
  {
    name: "franchise-components-BlockFaq___kk",
    path: "/franchise/components/BlockFaq",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue")
  },
  {
    name: "franchise-components-BlockFaq___en",
    path: "/en/franchise/components/BlockFaq",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue")
  },
  {
    name: "franchise-components-BlockFaq___ru",
    path: "/ru/franchise/components/BlockFaq",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue")
  },
  {
    name: "franchise-components-ConsultationBlock___kk",
    path: "/franchise/components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue")
  },
  {
    name: "franchise-components-ConsultationBlock___en",
    path: "/en/franchise/components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue")
  },
  {
    name: "franchise-components-ConsultationBlock___ru",
    path: "/ru/franchise/components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue")
  },
  {
    name: "franchise-components-ConsultationForm___kk",
    path: "/franchise/components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue")
  },
  {
    name: "franchise-components-ConsultationForm___en",
    path: "/en/franchise/components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue")
  },
  {
    name: "franchise-components-ConsultationForm___ru",
    path: "/ru/franchise/components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue")
  },
  {
    name: "franchise-components-FilialOpen___kk",
    path: "/franchise/components/FilialOpen",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue")
  },
  {
    name: "franchise-components-FilialOpen___en",
    path: "/en/franchise/components/FilialOpen",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue")
  },
  {
    name: "franchise-components-FilialOpen___ru",
    path: "/ru/franchise/components/FilialOpen",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue")
  },
  {
    name: "franchise-components-FranchiseBlock___kk",
    path: "/franchise/components/FranchiseBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue")
  },
  {
    name: "franchise-components-FranchiseBlock___en",
    path: "/en/franchise/components/FranchiseBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue")
  },
  {
    name: "franchise-components-FranchiseBlock___ru",
    path: "/ru/franchise/components/FranchiseBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-MapBlock___kk",
    path: "/franchise/components/mapBlock/MapBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-MapBlock___en",
    path: "/en/franchise/components/mapBlock/MapBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-MapBlock___ru",
    path: "/ru/franchise/components/mapBlock/MapBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-PalletBlock___kk",
    path: "/franchise/components/mapBlock/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-PalletBlock___en",
    path: "/en/franchise/components/mapBlock/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue")
  },
  {
    name: "franchise-components-mapBlock-PalletBlock___ru",
    path: "/ru/franchise/components/mapBlock/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue")
  },
  {
    name: "franchise-components-ModalError___kk",
    path: "/franchise/components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue")
  },
  {
    name: "franchise-components-ModalError___en",
    path: "/en/franchise/components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue")
  },
  {
    name: "franchise-components-ModalError___ru",
    path: "/ru/franchise/components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue")
  },
  {
    name: "franchise-components-ModalSuccess___kk",
    path: "/franchise/components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue")
  },
  {
    name: "franchise-components-ModalSuccess___en",
    path: "/en/franchise/components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue")
  },
  {
    name: "franchise-components-ModalSuccess___ru",
    path: "/ru/franchise/components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue")
  },
  {
    name: "franchise-components-pallet-PalletBlock___kk",
    path: "/franchise/components/pallet/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue")
  },
  {
    name: "franchise-components-pallet-PalletBlock___en",
    path: "/en/franchise/components/pallet/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue")
  },
  {
    name: "franchise-components-pallet-PalletBlock___ru",
    path: "/ru/franchise/components/pallet/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue")
  },
  {
    name: "franchise-components-pallet-PalletOne___kk",
    path: "/franchise/components/pallet/PalletOne",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue")
  },
  {
    name: "franchise-components-pallet-PalletOne___en",
    path: "/en/franchise/components/pallet/PalletOne",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue")
  },
  {
    name: "franchise-components-pallet-PalletOne___ru",
    path: "/ru/franchise/components/pallet/PalletOne",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue")
  },
  {
    name: "franchise-components-partnerBlock-BlockDescription___kk",
    path: "/franchise/components/partnerBlock/BlockDescription",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue")
  },
  {
    name: "franchise-components-partnerBlock-BlockDescription___en",
    path: "/en/franchise/components/partnerBlock/BlockDescription",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue")
  },
  {
    name: "franchise-components-partnerBlock-BlockDescription___ru",
    path: "/ru/franchise/components/partnerBlock/BlockDescription",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue")
  },
  {
    name: "franchise-components-partnerBlock-PartnersBlock___kk",
    path: "/franchise/components/partnerBlock/PartnersBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue")
  },
  {
    name: "franchise-components-partnerBlock-PartnersBlock___en",
    path: "/en/franchise/components/partnerBlock/PartnersBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue")
  },
  {
    name: "franchise-components-partnerBlock-PartnersBlock___ru",
    path: "/ru/franchise/components/partnerBlock/PartnersBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PalletBlock___kk",
    path: "/franchise/components/pekPlus/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PalletBlock___en",
    path: "/en/franchise/components/pekPlus/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PalletBlock___ru",
    path: "/ru/franchise/components/pekPlus/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PekPlusBlock___kk",
    path: "/franchise/components/pekPlus/PekPlusBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PekPlusBlock___en",
    path: "/en/franchise/components/pekPlus/PekPlusBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue")
  },
  {
    name: "franchise-components-pekPlus-PekPlusBlock___ru",
    path: "/ru/franchise/components/pekPlus/PekPlusBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue")
  },
  {
    name: "franchise___kk",
    path: "/franchise",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue")
  },
  {
    name: "franchise___en",
    path: "/en/franchise",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue")
  },
  {
    name: "franchise___ru",
    path: "/ru/franchise",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue")
  },
  {
    name: "index-components-AdvantageBlock___kk",
    path: "//components/AdvantageBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue")
  },
  {
    name: "index-components-AdvantageBlock___en",
    path: "/en//components/AdvantageBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue")
  },
  {
    name: "index-components-AdvantageBlock___ru",
    path: "/ru//components/AdvantageBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue")
  },
  {
    name: "index-components-BannerSlider___kk",
    path: "//components/BannerSlider",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue")
  },
  {
    name: "index-components-BannerSlider___en",
    path: "/en//components/BannerSlider",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue")
  },
  {
    name: "index-components-BannerSlider___ru",
    path: "/ru//components/BannerSlider",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue")
  },
  {
    name: "index-components-BlockTitle___kk",
    path: "//components/BlockTitle",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue")
  },
  {
    name: "index-components-BlockTitle___en",
    path: "/en//components/BlockTitle",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue")
  },
  {
    name: "index-components-BlockTitle___ru",
    path: "/ru//components/BlockTitle",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue")
  },
  {
    name: "index-components-ConsultationBlock___kk",
    path: "//components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue")
  },
  {
    name: "index-components-ConsultationBlock___en",
    path: "/en//components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue")
  },
  {
    name: "index-components-ConsultationBlock___ru",
    path: "/ru//components/ConsultationBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue")
  },
  {
    name: "index-components-ConsultationForm___kk",
    path: "//components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue")
  },
  {
    name: "index-components-ConsultationForm___en",
    path: "/en//components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue")
  },
  {
    name: "index-components-ConsultationForm___ru",
    path: "/ru//components/ConsultationForm",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue")
  },
  {
    name: "index-components-ModalError___kk",
    path: "//components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue")
  },
  {
    name: "index-components-ModalError___en",
    path: "/en//components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue")
  },
  {
    name: "index-components-ModalError___ru",
    path: "/ru//components/ModalError",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue")
  },
  {
    name: "index-components-ModalSuccess___kk",
    path: "//components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue")
  },
  {
    name: "index-components-ModalSuccess___en",
    path: "/en//components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue")
  },
  {
    name: "index-components-ModalSuccess___ru",
    path: "/ru//components/ModalSuccess",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue")
  },
  {
    name: "index___kk",
    path: "/",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue")
  },
  {
    name: "index-sections-DefaultAdvantagesSection___kk",
    path: "//sections/DefaultAdvantagesSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue")
  },
  {
    name: "index-sections-DefaultAdvantagesSection___en",
    path: "/en//sections/DefaultAdvantagesSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue")
  },
  {
    name: "index-sections-DefaultAdvantagesSection___ru",
    path: "/ru//sections/DefaultAdvantagesSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue")
  },
  {
    name: "index-sections-DefaultHeaderSection___kk",
    path: "//sections/DefaultHeaderSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue")
  },
  {
    name: "index-sections-DefaultHeaderSection___en",
    path: "/en//sections/DefaultHeaderSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue")
  },
  {
    name: "index-sections-DefaultHeaderSection___ru",
    path: "/ru//sections/DefaultHeaderSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue")
  },
  {
    name: "index-sections-DescriptionSection___kk",
    path: "//sections/DescriptionSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue")
  },
  {
    name: "index-sections-DescriptionSection___en",
    path: "/en//sections/DescriptionSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue")
  },
  {
    name: "index-sections-DescriptionSection___ru",
    path: "/ru//sections/DescriptionSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue")
  },
  {
    name: "index-sections-FaqSection___kk",
    path: "//sections/FaqSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue")
  },
  {
    name: "index-sections-FaqSection___en",
    path: "/en//sections/FaqSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue")
  },
  {
    name: "index-sections-FaqSection___ru",
    path: "/ru//sections/FaqSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue")
  },
  {
    name: "index-sections-InfoSection___kk",
    path: "//sections/InfoSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue")
  },
  {
    name: "index-sections-InfoSection___en",
    path: "/en//sections/InfoSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue")
  },
  {
    name: "index-sections-InfoSection___ru",
    path: "/ru//sections/InfoSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue")
  },
  {
    name: "index-sections-MapSection___kk",
    path: "//sections/MapSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue")
  },
  {
    name: "index-sections-MapSection___en",
    path: "/en//sections/MapSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue")
  },
  {
    name: "index-sections-MapSection___ru",
    path: "/ru//sections/MapSection",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue")
  },
  {
    name: "index-utils-getAddressInfo___kk",
    path: "//utils/getAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js")
  },
  {
    name: "index-utils-getAddressInfo___en",
    path: "/en//utils/getAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js")
  },
  {
    name: "index-utils-getAddressInfo___ru",
    path: "/ru//utils/getAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js")
  },
  {
    name: "index-utils-getDirectionParamsFromAddressInfo___kk",
    path: "//utils/getDirectionParamsFromAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js")
  },
  {
    name: "index-utils-getDirectionParamsFromAddressInfo___en",
    path: "/en//utils/getDirectionParamsFromAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js")
  },
  {
    name: "index-utils-getDirectionParamsFromAddressInfo___ru",
    path: "/ru//utils/getDirectionParamsFromAddressInfo",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js")
  },
  {
    name: "index-utils-getNeedCalculate___kk",
    path: "//utils/getNeedCalculate",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js")
  },
  {
    name: "index-utils-getNeedCalculate___en",
    path: "/en//utils/getNeedCalculate",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js")
  },
  {
    name: "index-utils-getNeedCalculate___ru",
    path: "/ru//utils/getNeedCalculate",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js")
  },
  {
    name: "index-utils-getSugggest___kk",
    path: "//utils/getSugggest",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js")
  },
  {
    name: "index-utils-getSugggest___en",
    path: "/en//utils/getSugggest",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js")
  },
  {
    name: "index-utils-getSugggest___ru",
    path: "/ru//utils/getSugggest",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js")
  },
  {
    name: "index-utils-getTransitCostBlockMessage___kk",
    path: "//utils/getTransitCostBlockMessage",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js")
  },
  {
    name: "index-utils-getTransitCostBlockMessage___en",
    path: "/en//utils/getTransitCostBlockMessage",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js")
  },
  {
    name: "index-utils-getTransitCostBlockMessage___ru",
    path: "/ru//utils/getTransitCostBlockMessage",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js")
  },
  {
    name: "index-utils-isProductCode___kk",
    path: "//utils/isProductCode",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js")
  },
  {
    name: "index-utils-isProductCode___en",
    path: "/en//utils/isProductCode",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js")
  },
  {
    name: "index-utils-isProductCode___ru",
    path: "/ru//utils/isProductCode",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js")
  },
  {
    name: "news-id___kk",
    path: "/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue")
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue")
  },
  {
    name: "news-id___ru",
    path: "/ru/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue")
  },
  {
    name: "news___kk",
    path: "/news",
    meta: indexYe5dKZsJo1Meta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: indexYe5dKZsJo1Meta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue")
  },
  {
    name: "news___ru",
    path: "/ru/news",
    meta: indexYe5dKZsJo1Meta || {},
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue")
  },
  {
    name: "package-components-Category___kk",
    path: "/package/components/Category",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue")
  },
  {
    name: "package-components-Category___en",
    path: "/en/package/components/Category",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue")
  },
  {
    name: "package-components-Category___ru",
    path: "/ru/package/components/Category",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue")
  },
  {
    name: "package-components-CategoryPallet___kk",
    path: "/package/components/CategoryPallet",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue")
  },
  {
    name: "package-components-CategoryPallet___en",
    path: "/en/package/components/CategoryPallet",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue")
  },
  {
    name: "package-components-CategoryPallet___ru",
    path: "/ru/package/components/CategoryPallet",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue")
  },
  {
    name: "package-components-PalletBlock___kk",
    path: "/package/components/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue")
  },
  {
    name: "package-components-PalletBlock___en",
    path: "/en/package/components/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue")
  },
  {
    name: "package-components-PalletBlock___ru",
    path: "/ru/package/components/PalletBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue")
  },
  {
    name: "package-components-TableSearch-HeadWithSearch___kk",
    path: "/package/components/TableSearch/HeadWithSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue")
  },
  {
    name: "package-components-TableSearch-HeadWithSearch___en",
    path: "/en/package/components/TableSearch/HeadWithSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue")
  },
  {
    name: "package-components-TableSearch-HeadWithSearch___ru",
    path: "/ru/package/components/TableSearch/HeadWithSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue")
  },
  {
    name: "package-components-TableSearch-TableBlock___kk",
    path: "/package/components/TableSearch/TableBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue")
  },
  {
    name: "package-components-TableSearch-TableBlock___en",
    path: "/en/package/components/TableSearch/TableBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue")
  },
  {
    name: "package-components-TableSearch-TableBlock___ru",
    path: "/ru/package/components/TableSearch/TableBlock",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue")
  },
  {
    name: "package-components-TableSearch-TableRow___kk",
    path: "/package/components/TableSearch/TableRow",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue")
  },
  {
    name: "package-components-TableSearch-TableRow___en",
    path: "/en/package/components/TableSearch/TableRow",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue")
  },
  {
    name: "package-components-TableSearch-TableRow___ru",
    path: "/ru/package/components/TableSearch/TableRow",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue")
  },
  {
    name: "package-components-TableSearch-TableSearch___kk",
    path: "/package/components/TableSearch/TableSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue")
  },
  {
    name: "package-components-TableSearch-TableSearch___en",
    path: "/en/package/components/TableSearch/TableSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue")
  },
  {
    name: "package-components-TableSearch-TableSearch___ru",
    path: "/ru/package/components/TableSearch/TableSearch",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue")
  },
  {
    name: "package___kk",
    path: "/package",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue")
  },
  {
    name: "package___en",
    path: "/en/package",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue")
  },
  {
    name: "package___ru",
    path: "/ru/package",
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue")
  }
]