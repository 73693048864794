import unhead_KgADcZ0jPj from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/pecom.kz/www/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/pecom.kz/www/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/pecom.kz/www/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/pecom.kz/www/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/var/www/pecom.kz/www/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import vue_dompurify_html_dkB45ZSnPU from "/var/www/pecom.kz/www/frontend/plugins/vue-dompurify-html.js";
import maska_r8BHBbSmHt from "/var/www/pecom.kz/www/frontend/plugins/maska.js";
import routesPiniaInit_fn8sWidGJv from "/var/www/pecom.kz/www/frontend/plugins/routesPiniaInit.js";
import vue_awesome_paginate_l1b0wTlGPB from "/var/www/pecom.kz/www/frontend/plugins/vue-awesome-paginate.js";
import vue_datepicker_65xHnOJJH3 from "/var/www/pecom.kz/www/frontend/plugins/vue-datepicker.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  vue_dompurify_html_dkB45ZSnPU,
  maska_r8BHBbSmHt,
  routesPiniaInit_fn8sWidGJv,
  vue_awesome_paginate_l1b0wTlGPB,
  vue_datepicker_65xHnOJJH3
]