<template>
    <div class="error_page">
        <div class="error_page_block">
            <div class="error_page_code_block">
                <span class="error_page_code">
                    {{ error.statusCode }}
                </span>
            </div>
            <div class="error_page_text_block">
                <span class="error_page_text">
                    {{ error.message }}
                </span>
            </div>
            <div v-if="env.MODE === `development`">
                ENV:
                <pre>{{ env }}</pre>
                runtimeConfig:
                <pre>{{ runtimeConfig }}</pre>
                stack:
                <pre>{{ error.stack }}</pre>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRuntimeConfig } from '#app';

defineProps({
    error: {
        type: Object,
        default: () => ({}),
    },
});

const runtimeConfig = useRuntimeConfig();
const env = import.meta.env;
</script>

<style scoped>
.error_page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error_page_block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error_page_code {
    font-size: 5em;
    color: #e4003c;
}
</style>
