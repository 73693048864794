import validate from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/pecom.kz/www/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  available: () => import("/var/www/pecom.kz/www/frontend/middleware/available.ts"),
  "check-news": () => import("/var/www/pecom.kz/www/frontend/middleware/checkNews.ts"),
  "clean-store-news": () => import("/var/www/pecom.kz/www/frontend/middleware/cleanStoreNews.ts")
}