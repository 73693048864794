import { defineStore } from 'pinia';
import type { routeEntity } from '~/@types/routes/Routes';

export const avalibeRouter = defineStore('avalibeRouter', () => {
    const routes = ref<routeEntity[]>([]);
    const setRoutes = (routesArrray: routeEntity[]) => (routes.value = [...routesArrray]);
    const getIdByUrl = (url: string) => {
        const finded = routes.value.find(i => i.url === url);
        return finded === undefined ? null : finded.id;
    };
    return { routes, setRoutes, getIdByUrl };
});
