export const useLang = defineStore('langStore', () => {
    const langCode = ref('kk');
    const langOptions = ref({});
    const languages = ref([]);
    const setLangCode = code => {
        langCode.value = code || 'kk';
        langOptions.value = languages.value.find(item => item.code === langCode.value);
    };
    const setLanguages = array => {
        languages.value = array;
    };
    return { langCode, langOptions, languages, setLangCode, setLanguages };
});
